<template>
    <div class="articleList">
        <div class="header">
            <img src="../../assets/image/publication/header.jpg" alt=""/>
        </div>
        <div class="container">
            <div class="select-box">
                <span class="title">Select Year:</span>
                <el-select v-model="yearValue" clearable placeholder="select">
                    <el-option
                        v-for="item in yearList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="list-box">
                <div class="list-item" v-for="(item, i) in currentData" :key="i">
                    <div class="time">{{ item.time }}</div>
                    <div class="title">
                        <span @click="goToUrl(item)">{{ item.value }}</span>
                        <div @click="openPdf(item)" class="download-icon"></div>
                    </div>
                    <div class="subTitle">{{ item.subTitle }}</div>
                </div>

                <div class="page-box">
                    <el-pagination
                        background
                        @size-change="sizeChange"
                        @current-change="currentChange"
                        :current-page="page"
                        :page-size="size"
                        layout="prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- <div class="footer">
          <div class="appLogo-box">
            <a href="https://www.facebook.com/RecoveryPlus.health">
              <img src="../../assets/image/pc/footer/facebook.png" alt="" />
            </a>
            <a href="https://www.instagram.com/recoveryplus.health/">
              <img src="../../assets/image/pc/footer/ins.png" alt="" />
            </a>
            <a href="https://twitter.com/recoveryplus_h?s=21">
              <img src="../../assets/image/pc/footer/Twitter.png" alt="" />
            </a>
            <a href="https://www.linkedin.com/company/recovery-plus-h">
              <img src="../../assets/image/pc/footer/in.png" alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UCI2Qir_opbytfE1dOmbLUIg">
              <img src="../../assets/image/pc/footer/YouTube.png" alt="" />
            </a>
          </div>
          <div class="textStyle">&copy; 2023 Recovery Plus, Inc.</div>
        </div> -->
        <foot-block></foot-block>
    </div>
</template>

<script>
import dataJson from '../../libs/data.json'
import FootBlock from "../../components/FootBlock/FootBlock.vue"

export default {
    components: {
        "foot-block": FootBlock
    },
    data() {
        return {
            yearValue: "",
            yearList: [],
            articleListData: [], //全部数据
            currentData: [], //当前页数展示数据
            page: 1, //第几页
            size: 7, //一页多少条
            total: 0, //总条目数
        };
    },
    watch: {
        yearValue: {
            deep: true,
            handler(n) {
                // console.log(n);
                if (n && n != "") {
                    let newArr = [];
                    this.articleListData.forEach((item) => {
                        if (item.year == n) {
                            newArr.push(item);
                        }
                    });
                    this.getTabelData(newArr);
                    console.log(newArr);
                } else if (n == "") {
                    this.getTabelData();
                }
            },
        },
    },
    created() {
        this.articleListData = dataJson.data.list
        this.getTabelData(this.articleListData);
        let yearList = []
        for(let item of this.articleListData){
            if(!yearList.includes(item.year)){
                yearList.push(item.year)
            }
        }
        yearList.sort(function(a, b){return b-a})
        for(let item of yearList){
            this.yearList.push(
                {
                    value: item,
                    label: item,
                }
            )
        }
    },

    methods: {
        getTabelData(arr) {
            //allData为全部数据
            let currentArr = arr || this.articleListData;
            this.currentData = currentArr.slice(
                (this.page - 1) * this.size,
                this.page * this.size
            );
            this.total = currentArr.length;
        },

        currentChange(val) {
            console.log("翻页，当前为第几页", val);
            this.page = val;
            this.getTabelData();
        },

        sizeChange(val) {
            this.size = val;
            this.page = 1;
            this.getTabelData();
        },
        goToUrl(item) {
            window.open(item.url, "_blank");
        },

        openPdf(item) {
            window.open(item.src, "_blank");
        },
    },
};
</script>


<style lang="scss" scoped>
@media screen and (min-width: $minDeviceWidth_pc) {
    @import "publication_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
    @import "publication_mobile";
}
</style>