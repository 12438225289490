import Vue from 'vue'
import Router from 'vue-router'
import Wrap from "../components/Wrap/Wrap"
import Publication from '../view/publication/publication'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: '_home',
      redirect: '/home',
      component: Wrap,
      children: [
        {
          path: '/home',
          name: 'home',
          meta: {
            title: '首页'
          },
          component: () => import('../view/home/home')
        }
      ]
    },
    {
      path: '/app',
      name: '_app',
      component: Wrap,
      children: [
        {
          path: '/app',
          name: 'app',
          meta: {
            title: '术康App'
          },
          component: () => import('../view/app/app')
        }
      ]
    },
    {
      path: '/overseas',
      name: '_overseas',
      component: Wrap,
      children: [
        {
          path: '/overseas',
          name: 'overseas',
          meta: {
            title: '术康海外'
          },
          component: () => import('../view/overseas/overseas')
        }
      ]
    },
    {
      path: '/nutrition',
      name: '_nutrition',
      component: Wrap,
      children: [
        {
          path: '/nutrition',
          name: 'nutrition',
          meta: {
            title: '术康营养科学'
          },
          component: () => import('../view/nutrition/nutrition')
        }
      ]
    },
    {
      path: '/brainfit',
      name: '_brainfit',
      component: Wrap,
      children: [
        {
          path: '/brainfit',
          name: 'brainfit',
          meta: {
            title: '术康认知训练'
          },
          component: () => import('../view/brainfit/brainfit')
        }
      ]
    },
    {
      path: '/about',
      name: '_about',
      component: Wrap,
      children: [
        {
          path: '/about',
          name: 'about',
          meta: {
            title: '关于我们'
          },
          component: () => import('../view/about/about')
        }
      ]
    },
    {
      path: '/investment',
      name: '_investment',
      component: Wrap,
      children: [
        {
          path: '/investment',
          name: 'investment',
          meta: {
            title: '招商'
          },
          component: () => import('../view/investment/investment')
        }
      ]
    },
    {
      path: '/articleList',
      name: 'articleList',
      component: Wrap,
      children: [
        {
          path: '/articleList',
          name: 'articleList',
          meta: {
            title: '文章列表'
          },
          component: () => import('../view/articleList/articleList')
        }
      ]
    },
    {
      path: '/article',
      name: 'article',
      component: Wrap,
      children: [
        {
          path: '/article',
          name: 'article',
          meta: {
            title: '文章详情'
          },
          component: () => import('../view/article/article')
        }
      ]
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('../view/privacy/privacy')
    },
    {
      path: '/patientRegister',
      name: 'patientRegister',
      component: () => import('../view/patientRegister/patientRegister')
    },
    {
      path: '/cooperation',
      name: '_cooperation',
      component: Wrap,
      children: [
        {
          path: '/cooperation',
          name: 'cooperation',
          meta: {
            title: '对外合作'
          },
          component: () => import('../view/cooperation/cooperation')
        }
      ]
    },
    // {
    //   path: '/publication',
    //   name: '_publication',
    //   component: Wrap,
    //   children: [
    //     {
    //       path: '/publication',
    //       name: 'publication',
    //       meta: {
    //         title: '期刊'
    //       },
    //       component: () => import('../view/publication/publication')
    //     }
    //   ]
    // },
    {
      path: '/publication',
      name: '_publication',
      component: Publication,
    }
      // 后面注释的都是没用的，暂时保留
    // {
    //   path: '/academic',
    //   name: '_academic',
    //   component: Wrap,
    //   children: [
    //     {
    //       path: '/academic',
    //       name: 'academic',
    //       meta: {
    //         title: '学术中心'
    //       },
    //       component: () => import('../view/academic/academic')
    //     }
    //   ]
    // },
    // {
    //   path: '/knowledge',
    //   name: '_knowledge',
    //   component: Wrap,
    //   children: [
    //     {
    //       path: '/knowledge',
    //       name: 'knowledge',
    //       meta: {
    //         title: '知识资讯'
    //       },
    //       component: () => import('../view/knowledge/knowledge')
    //     }
    //   ]
    // },
    // {
    //   path: '/recovery',
    //   name: '_recovery',
    //   component: Wrap,
    //   children: [
    //     {
    //       path: '/recovery',
    //       name: 'recovery',
    //       meta: {
    //         title: '知识资讯'
    //       },
    //       component: () => import('../view/recovery/recovery')
    //     }
    //   ]
    // },
    // {
    //   path: '/download',
    //   name: '_download',
    //   component: Wrap,
    //   children: [
    //     {
    //       path: '/download',
    //       name: 'download',
    //       meta: {
    //         title: '下载'
    //       },
    //       component: () => import('../view/download/download')
    //     }
    //   ]
    // },
    // {
    //   path: '/insurance',
    //   name: '_insurance',
    //   redirect: '/insurance',
    //   component: Wrap,
    //   children: [
    //     {
    //       path: '/insurance',
    //       name: 'insurance',
    //       meta: {
    //         title: '保险详情'
    //       },
    //       component: () => import('../view/insurance/insurance')
    //     }
    //   ]
    // },
    // {
    //   path: '/individuals',
    //   name: 'individuals',
    //   component: Wrap,
    //   children: [
    //     {
    //       path: '/individuals',
    //       name: 'individuals',
    //       meta: {
    //         title: 'individuals'
    //       },
    //       component: () => import('../view/individuals/individuals')
    //     }
    //   ]
    // },
    // {
    //   path: '/liushi',
    //   name: 'liushi',
    //   component: () => import('../view/product/product')
    // },
    // {
    //   path: '/jiangu',
    //   name: 'jiangu',
    //   component: () => import('../view/product/product')
    // },
    // {
    //   path: '/yingyangcan',
    //   name: 'yingyangcan',
    //   component: () => import('../view/product/product')
    // },
  ]
})
