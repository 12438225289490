import articleApi from '../../api/article'

const state = {
  getStatus:null,
  setStatus:null,
  getDataStatus:null
}

// getters
const getters = {
}

// actions
const actions = {
  getCategory ({ commit, state },obj) {
    return new Promise((resolve, reject) => {
      articleApi.getCategory(obj).then(res => {
        var resData = res.data
        resolve(resData)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getArticleById ({ commit, state },obj) {
    return new Promise((resolve, reject) => {
      articleApi.getArticleById(obj).then(res => {
        var resData = res.data
        resolve(resData)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getArticle ({ commit, state },obj) {
    return new Promise((resolve, reject) => {
      articleApi.getArticle(obj).then(res => {
        var resData = res.data
        resolve(resData)
      }).catch(err => {
        reject(err)
      })
    })
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
