import axios from 'axios'

class HttpRequest {
  constructor (baseUrl = baseUrl) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {

      }
    }
    return config
  }
  destroy (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      //Spin.hide()
    }
  }
  interceptors (instance, options) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      // 添加全局的loading...
      if (!Object.keys(this.queue).length) {
        //Spin.show() // 不建议开启，因为界面不友好
      }
      this.queue[options.url] = true
      //如果使用其他的网络接口
      if(options.host){
        config.baseURL = options.host
      }
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(options.url)
      const { data, status } = res
      return { data, status }
    }, error => {
      this.destroy(options.url)
      return Promise.reject(error)
    })
  }
  request (options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options)
    return instance(options)
  }
}
export default HttpRequest


