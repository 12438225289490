import HttpRequest from './axios'
import config from '../config'

var baseUrl=""
if(window.location.host.indexOf('localhost') > -1){
  baseUrl=config.baseUrl.dev
}else if(window.location.host.indexOf("www-cn.dev.shukangyun.com") > -1 || window.location.host.indexOf("www-zh-dev.rplushealth.cn") > -1) {
  baseUrl = config.baseUrl.dev
}else if(window.location.host.indexOf("shukangyun.com") > -1 || window.location.host.indexOf("postop.cn") > -1 || window.location.host.indexOf("rplushealth.com") > -1  ) {
  baseUrl = config.baseUrl.pro
}

//http://global.console.dev.shukangyun.com/global-console/api/category/list?page=0&size=100
//http://global.console.dev.shukangyun.com/global-console/article/category?category=&page=0&size=6

const axios = new HttpRequest(baseUrl)
export default axios
