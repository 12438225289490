<template>
  <div class="header">
    <div class="headBlock" :class="fixed_header">
      <div class="navBlock">
        <div class="logo" @click="jumpPage('home')"><img :src="logo_url"></div>
        <ul class="navBar">
          <li class="navCell" @click="jumpPage('home')" :class="{thisNav:pageName==='home'}">
            <span>首页</span>
          </li>
          <li class="navCell" @click="jumpPage('app')" :class="{thisNav:pageName==='app'}">
            <span>术康App</span>
          </li>
          <li class="navCell" @click="jumpPage('overseas')" :class="{thisNav:pageName==='overseas'}">
            <span>术康海外</span>
          </li>
          <li class="navCell" @click="jumpPage('nutrition')" :class="{thisNav:pageName==='nutrition'}">
            <span>术康营养科学</span>
          </li>
          <li class="navCell" @click="jumpPage('brainfit')" :class="{thisNav:pageName==='brainfit'}">
            <span>术康认知训练</span>
          </li>
<!--          <li class="navCell" @click="jumpPage('individuals')" :class="{thisNav:pageName=='individuals'}">-->
<!--            <span>个人康复</span>-->
<!--          </li>-->
<!--          <li class="navCell" @click="jumpPage('recovery')" :class="{thisNav:pageName=='recovery'}">-->
<!--            <span>企业团课</span>-->
<!--          </li>-->
<!--          <li class="navCell" @click="jumpPage('knowledge')" :class="{thisNav:pageName=='knowledge'}">-->
<!--            <span>医生管理</span>-->
<!--          </li>-->
          <li class="navCell" @click="jumpPage('articleList')" :class="{thisNav:pageName==='articleList'}">
            <span>学术动态</span>
          </li>
          <li class="navCell" @click="jumpPage('about')" :class="{thisNav:pageName==='about'}">
            <span>公益 · 我们</span>
          </li>
        </ul>
      </div>
    </div>

    <transition name="fade">
      <div v-if="fixed_header" class="headBlock-float fixed_header">
        <div class="navBlock">
          <div class="logo" @click="jumpPage('home')"><img :src="logo_url"></div>
          <ul class="navBar">
            <li class="navCell" @click="jumpPage('home')" :class="{thisNav:pageName==='home'}">
              <span>首页</span>
            </li>
            <li class="navCell" @click="jumpPage('app')" :class="{thisNav:pageName==='app'}">
              <span>术康App</span>
            </li>
            <li class="navCell" @click="jumpPage('overseas')" :class="{thisNav:pageName==='overseas'}">
              <span>术康海外</span>
            </li>
            <li class="navCell" @click="jumpPage('nutrition')" :class="{thisNav:pageName==='nutrition'}">
              <span>术康营养科学</span>
            </li>
            <li class="navCell" @click="jumpPage('brainfit')" :class="{thisNav:pageName==='brainfit'}">
              <span>术康认知训练</span>
            </li>
            <!--          <li class="navCell" @click="jumpPage('individuals')" :class="{thisNav:pageName=='individuals'}">-->
            <!--            <span>个人康复</span>-->
            <!--          </li>-->
            <!--          <li class="navCell" @click="jumpPage('recovery')" :class="{thisNav:pageName=='recovery'}">-->
            <!--            <span>企业团课</span>-->
            <!--          </li>-->
            <!--          <li class="navCell" @click="jumpPage('knowledge')" :class="{thisNav:pageName=='knowledge'}">-->
            <!--            <span>医生管理</span>-->
            <!--          </li>-->
            <li class="navCell" @click="jumpPage('articleList')" :class="{thisNav:pageName==='articleList'}">
              <span>学术动态</span>
            </li>
            <li class="navCell" @click="jumpPage('about')" :class="{thisNav:pageName==='about'}">
              <span>公益 · 我们</span>
            </li>
          </ul>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
  import logo from "../../assets/image/header/logo.png?v=1";


  export default {
    name: "heading",

    data() {
      return {
        pageName: "",
        fixed_header: false,
        logo_url: logo,
      }
    },
    created() {
      this.pageName = this.$route.name
    },
    mounted() {
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      });
      window.addEventListener("scroll", this.handlerScroll)
    },
    watch: {
      "$route.name"(n, o) {
        this.pageName = n
      }
    },
    methods: {
      handlerScroll() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 120) {
          this.fixed_header = true
        } else {
          this.fixed_header = false
        }
      },
      jumpPage(name) {
        this.$router.push({path: "/" + name})
      },
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $minDeviceWidth_pc) {
    @import "./HeadBlock_pc.scss";
  }

  @media screen and (max-width: $minDeviceWidth_pc) {
    @import "./HeadBlock_mobile.scss";
  }
</style>
