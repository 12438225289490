import Vue from 'vue'
import vuex from 'vuex'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'



import article_store from './modules/article'

Vue.use(vuex);

export default new vuex.Store({
  state: {
    device:"pc"
  },
  modules: {
    article: article_store,
  },
  actions:{...actions},
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  }
})
