import axios from "../libs/api.request"

export default {
  getCategory:(obj)=> {
    let page = parseInt(obj.page)
    let size = parseInt(obj.size)
    return axios.request({
      url: '/category/list?page='+page+'&size='+size,
      method: 'get'
    })
  },
  getArticleById: (obj) => {
    return axios.request({
      url: '/article/detail/' + obj.id,
      method: 'get'
    })
  },
  getArticle:(obj)=> {
    let page = parseInt(obj.page)
    let size = parseInt(obj.size)
    let category = obj.category || ""
    let title = obj.title || ""
    return axios.request({
      url: '/article/search?page='+page+'&size='+size+'&category='+category+'&title='+title,
      method: 'get'
    })
  },
}
