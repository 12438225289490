<template>
  <div class="footBlock">
    <div class="contactBlock">
      <div class="contactBlock-content">
        <div class="contactSection">
          <div class="cpInfo">
            <div class="cpLogo">
              <img src="../../assets/image/footer/footer_logo.png"/>
            </div>
            <div class="cpName">
              <span>成都尚医信息科技有限公司</span>
            </div>
          </div>
          <div class="focusUs">
            <div class="text">
              <span>关注我们</span>
            </div>
            <div class="focus-content">
              <a class="focus-item" href="https://weibo.com/p/1006065578529192/home?from=page_100606&mod=TAB&is_hot=1#place"
                 target="_blank">
                <img class="focus-icon" src="../../assets/image/footer/footer_icon_weibo.png"/>
                <img class="focus-qrCode" src="../../assets/image/header/weibo.jpg">
              </a>
              <a class="focus-item">
                <img class="focus-icon" src="../../assets/image/footer/footer_icon_weixin.png"/>
                <img class="focus-qrCode" src="../../assets/image/header/icon_erweima.png">
              </a>
              <a class="focus-item">
                <img class="focus-icon" src="../../assets/image/footer/footer_icon_facebook.png"/>
              </a>
              <a class="focus-item">
                <img class="focus-icon" src="../../assets/image/footer/footer_icon_youtube.png"/>
              </a>
            </div>
          </div>
          <div class="contactUs">
            <span>联系我们</span>
            <div class="contactUs-content">
              <ul style="width: 150px">
                <li>联系人：阮女士</li>
                <li>028-68360941</li>
              </ul>
              <ul>
                <li>成都市高新区天府大道北段1700号</li>
                <li>环球中心E2 1-3-1708</li>
              </ul>
            </div>
            <div class="contactUs-content">
              <ul style="width: 150px">
                <li>术康诊所</li>
                <li>028-83376872</li>
              </ul>
              <ul>
                <li>成都市高新区天府大道北段1700号</li>
                <li>环球中心 E2 1-3-608</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyrightBlock">
      <span class="text">COPYRIGHT &copy; 2016 SHANGYI.All RIGHTS RESERVED.</span> <span class="link" @click="jump">蜀ICP备14024756号</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "FootBlock",
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    jump() {
      window.open("//beian.miit.gov.cn")
    },
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
  @import "./FootBlock_pc.scss";
}

@media screen and (max-width: $minDeviceWidth_pc) {
  @import "./FootBlock_mobile.scss";
}
</style>
