var env = process.env.NODE_ENV

export default {
  baseUrl: {
    //dev: 'https://global-console-dev.rplushealth.cn/global-console/api/',
    dev: 'https://global-console.rplushealth.cn/global-console/api/',
    pro: 'https://global-console.rplushealth.cn/global-console/api/',
    fat: 'http://global.console.fat.shukangyun.com/global-console/api/',
    uat: 'http://global.console.uat.shukangyun.com/global-console/api/',
    fpro: 'https://global-console.rplushealth.com/global-console/api/',
  },
  version:"1.0.0.0",
  env:env,
}
