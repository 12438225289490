<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    created(){
    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    max-width: 1920px;
    margin: 0 auto;
  }
  body{
    width: 100%;
    margin: 0;
  }
</style>
